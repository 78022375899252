// // src/components/CommentSection.js
// import React, { useState, useEffect } from 'react';
// import { databases, Query } from '../appwrite/appwrite'; // Import Appwrite databases instance
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';
// import Divider from './Divider';

// const CommentSection = ({ movieId }) => {
//   const [commentsList, setCommentsList] = useState([]);
//   const [name, setName] = useState('');
//   const [comment, setComment] = useState('');
//   const [rating, setRating] = useState(0);
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   useEffect(() => {
//     const fetchComments = async () => {
//       try {
//         const response = await databases.listDocuments(
//           '6680463f0025c96aaf8e',
//           '66b7d7f8001006e6f9a1',
//           [Query.equal('movie_id', movieId)]
//         );
//         setCommentsList(response.documents);
//       } catch (error) {
//         console.error('Failed to fetch comments:', error);
//       }
//     };

//     fetchComments();
//   }, [movieId]);

//   const handleCommentSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     try {
//       const response = await databases.createDocument(
//         '6680463f0025c96aaf8e', // Database ID
//         '66b7d7f8001006e6f9a1', // Collection ID for comments
//         'unique()', // Document ID, you can use 'unique()' to auto-generate an ID
//         {
//           movie_id: movieId,
//           user_name: name,
//           content: comment,
//           rating: rating
//         }
//       );
//       setCommentsList((prevComments) => [...prevComments, response]);
//       setName('');
//       setComment('');
//       setRating(0);
//     } catch (error) {
//       console.error('Failed to submit comment:', error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 1; i <= 10; i++) {
//       stars.push(
//         <FontAwesomeIcon
//           key={i}
//           icon={faStar}
//           className={i <= rating ? 'text-yellow-500' : 'text-gray-400'}
//           onClick={() => setRating(i)}
//           style={{ cursor: 'pointer' }}
//         />
//       );
//     }
//     return stars;
//   };

//   return (
//     <div className='container mx-auto px-3 py-8'>
//       <h3 className='text-xl font-bold text-white mb-4'>Leave a Comment</h3>
//       <form onSubmit={handleCommentSubmit} className='bg-gray-800 p-4 rounded mb-8'>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Name</label>
//           <input
//             type='text'
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className='w-full p-2 rounded text-black'
//             required
//           />
//         </div>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Comment</label>
//           <textarea
//             value={comment}
//             onChange={(e) => setComment(e.target.value)}
//             className='w-full p-2 rounded text-black'
//             rows='4'
//             required
//           ></textarea>
//         </div>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Rating</label>
//           <div className='flex'>{renderStars(rating)}</div>
//         </div>
//         <button
//           type='submit'
//           className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all'
//           disabled={isSubmitting}
//         >
//           {isSubmitting ? 'Submitting...' : 'Submit'}
//         </button>
//       </form>

//       <h3 className='text-xl font-bold text-white mb-4'>Comments</h3>
//       {commentsList.length > 0 && (
//         <div className='bg-gray-800 p-4 rounded'>
//           {commentsList.map((commentItem, index) => (
//             <div key={index} className='mb-4'>
//               <div className='flex items-center mb-2'>
//                 <FontAwesomeIcon icon={faUser} className='text-white mr-2' />
//                 <p className='text-white'><b>{commentItem.user_name}</b></p>
//               </div>
//               <p className='text-gray-300'>{commentItem.content}</p>
//               <div className='flex items-center my-2'>
//                 {renderStars(commentItem.rating)}
//               </div>
//               <p className='text-gray-400 text-sm'>{moment(commentItem.$createdAt).fromNow()}</p>
//               <Divider />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CommentSection;

// src/components/CommentSection.js
// import React, { useState, useEffect } from 'react';
// import { databases, Query } from '../appwrite/appwrite'; // Import Appwrite databases instance
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';
// import Divider from './Divider';

// const CommentSection = ({ movieId }) => {
//   const [commentsList, setCommentsList] = useState([]);
//   const [name, setName] = useState('');
//   const [comment, setComment] = useState('');
//   const [rating, setRating] = useState(0.0); // Initialize rating as float
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   useEffect(() => {
//     const fetchComments = async () => {
//       try {
//         const response = await databases.listDocuments(
//           '6680463f0025c96aaf8e',
//           '66b7d7f8001006e6f9a1',
//           [Query.equal('movie_id', movieId)]
//         );
//         setCommentsList(response.documents);
//       } catch (error) {
//         console.error('Failed to fetch comments:', error);
//       }
//     };

//     fetchComments();
//   }, [movieId]);

//   const handleCommentSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     try {
//       const response = await databases.createDocument(
//         '6680463f0025c96aaf8e', // Database ID
//         '66b7d7f8001006e6f9a1', // Collection ID for comments
//         'unique()', // Document ID, you can use 'unique()' to auto-generate an ID
//         {
//           movie_id: movieId,
//           user_name: name,
//           content: comment,
//           rating: rating
//         }
//       );
//       setCommentsList((prevComments) => [...prevComments, response]);
//       setName('');
//       setComment('');
//       setRating(0.0);
//     } catch (error) {
//       console.error('Failed to submit comment:', error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 1; i <= 10; i++) {
//       const filled = rating >= i;
//       const half = rating >= i - 0.5 && rating < i;
//       stars.push(
//         <FontAwesomeIcon
//           key={i}
//           icon={faStar}
//           className={filled ? 'text-yellow-500' : half ? 'text-yellow-300' : 'text-gray-400'}
//           onClick={() => setRating(i)}
//           style={{ cursor: 'pointer' }}
//         />
//       );
//     }
//     return stars;
//   };

//   return (
//     <div className='container mx-auto px-3 py-8'>
//       <h3 className='text-xl font-bold text-white mb-4'>Leave a Comment</h3>
//       <form onSubmit={handleCommentSubmit} className='bg-gray-800 p-4 rounded mb-8'>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Name</label>
//           <input
//             type='text'
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className='w-full p-2 rounded text-black'
//             required
//           />
//         </div>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Comment</label>
//           <textarea
//             value={comment}
//             onChange={(e) => setComment(e.target.value)}
//             className='w-full p-2 rounded text-black'
//             rows='4'
//             required
//           ></textarea>
//         </div>
//         <div className='mb-4'>
//           <label className='block text-white mb-2'>Rating</label>
//           <div className='flex mb-2'>{renderStars(rating)}</div>
//           <input
//             type='number'
//             step='0.1'
//             max='10'
//             value={rating}
//             onChange={(e) => setRating(parseFloat(e.target.value))}
//             className='w-25 p-2 rounded text-black'
//             required
//           />
//           {/* <div className='flex mt-2'>{renderStars(rating)}</div> */}
//         </div>
//         <button
//           type='submit'
//           className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all'
//           disabled={isSubmitting}
//         >
//           {isSubmitting ? 'Submitting...' : 'Submit'}
//         </button>
//       </form>

//       <h3 className='text-xl font-bold text-white mb-4'>Comments</h3>
//       {commentsList.length === 0 && (
//         <p className='text-white'>No comments yet.</p>
//       )}
//       {commentsList.length > 0 && (
//         <div className='bg-gray-800 p-4 rounded'>
//           {commentsList.map((commentItem, index) => (
//             <div key={index} className='mb-4'>
//               <div className='flex items-center mb-2'>
//                 <FontAwesomeIcon icon={faUser} className='text-white mr-2' />
//                 <p className='text-white'><b>{commentItem.user_name}</b></p>
//               </div>
//               <p className='text-gray-300'>{commentItem.content}</p>
//               <div className='flex items-center my-2'>
//                 {renderStars(commentItem.rating)}<span> ({commentItem.rating})</span>
//               </div>
//               <p className='text-gray-400 text-sm'>{moment(commentItem.$createdAt).fromNow()}</p>
//               <Divider />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CommentSection;

// import React, { useState, useEffect } from 'react';
// import { databases, account, Query } from '../appwrite/appwrite'; // Import Appwrite account instance
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';
// import Divider from './Divider';

// const CommentSection = ({ movieId }) => {
//   const [commentsList, setCommentsList] = useState([]);
//   const [user, setUser] = useState(null); // State to hold user details
//   const [comment, setComment] = useState('');
//   const [rating, setRating] = useState(0.0); // Initialize rating as float
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [error, setError] = useState(null); // State to handle errors

//   useEffect(() => {
//     const fetchUserAndComments = async () => {
//       try {
//         // Get user details if authenticated
//         const userResponse = await account.get();
//         setUser(userResponse);

//         // Fetch comments for the movie
//         const response = await databases.listDocuments(
//           '6680463f0025c96aaf8e',
//           '66b7d7f8001006e6f9a1',
//           [Query.equal('movie_id', movieId)]
//         );
//         setCommentsList(response.documents);
//       } catch (error) {
//         if (error.code === 401) {
//           setError('You must be logged in to comment.');
//         } else {
//           console.error('Failed to fetch comments or user details:', error);
//         }
//       }
//     };

//     fetchUserAndComments();
//   }, [movieId]);

//   const handleCommentSubmit = async (e) => {
//     e.preventDefault();
//     if (!user) {
//       setError('You must be logged in to comment.');
//       return;
//     }

//     setIsSubmitting(true);

//     try {
//       const response = await databases.createDocument(
//         '6680463f0025c96aaf8e', // Database ID
//         '66b7d7f8001006e6f9a1', // Collection ID for comments
//         'unique()', // Document ID, you can use 'unique()' to auto-generate an ID
//         {
//           movie_id: movieId,
//           user_name: user.name, // Use authenticated user's name
//           content: comment,
//           rating: rating
//         }
//       );
//       setCommentsList((prevComments) => [...prevComments, response]);
//       setComment('');
//       setRating(0.0);
//     } catch (error) {
//       console.error('Failed to submit comment:', error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 1; i <= 10; i++) {
//       const filled = rating >= i;
//       const half = rating >= i - 0.5 && rating < i;
//       stars.push(
//         <FontAwesomeIcon
//           key={i}
//           icon={faStar}
//           className={filled ? 'text-yellow-500' : half ? 'text-yellow-300' : 'text-gray-400'}
//           onClick={() => setRating(i)}
//           style={{ cursor: 'pointer' }}
//         />
//       );
//     }
//     return stars;
//   };

//   return (
//     <div className='container mx-auto px-3 py-8'>
//       <h3 className='text-xl font-bold text-white mb-4'>Leave a Comment</h3>
//       {error && <p className='text-red-500 mb-4'>{error}</p>}
//       {user ? (
//         <form onSubmit={handleCommentSubmit} className='bg-gray-800 p-4 rounded mb-8'>
//           <div className='mb-4'>
//             <label className='block text-white mb-2'>Comment</label>
//             <textarea
//               value={comment}
//               onChange={(e) => setComment(e.target.value)}
//               className='w-full p-2 rounded text-black'
//               rows='4'
//               required
//             ></textarea>
//           </div>
//           <div className='mb-4'>
//             <label className='block text-white mb-2'>Rating</label>
//             <div className='flex mb-2'>{renderStars(rating)}</div>
//             <input
//               type='number'
//               step='0.1'
//               max='10'
//               value={rating}
//               onChange={(e) => setRating(parseFloat(e.target.value))}
//               className='w-25 p-2 rounded text-black'
//               required
//             />
//           </div>
//           <button
//             type='submit'
//             className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all'
//             disabled={isSubmitting}
//           >
//             {isSubmitting ? 'Submitting...' : 'Submit'}
//           </button>
//         </form>
//       ) : (
//         <p className='text-white'>You must be logged in to leave a comment.</p>
//       )}

//       <h3 className='text-xl font-bold text-white mb-4'>Comments</h3>
//       {commentsList.length === 0 && (
//         <p className='text-white'>No comments yet.</p>
//       )}
//       {commentsList.length > 0 && (
//         <div className='bg-gray-800 p-4 rounded'>
//           {commentsList.map((commentItem, index) => (
//             <div key={index} className='mb-4'>
//               <div className='flex items-center mb-2'>
//                 <FontAwesomeIcon icon={faUser} className='text-white mr-2' />
//                 <p className='text-white'><b>{commentItem.user_name}</b></p>
//               </div>
//               <p className='text-gray-300'>{commentItem.content}</p>
//               <div className='flex items-center my-2'>
//                 {renderStars(commentItem.rating)}<span> ({commentItem.rating})</span>
//               </div>
//               <p className='text-gray-400 text-sm'>{moment(commentItem.$createdAt).fromNow()}</p>
//               <Divider />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CommentSection;


import React, { useState, useEffect } from 'react';
import { databases, account, Query } from '../appwrite/appwrite'; // Import Appwrite account instance
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';
import Divider from './Divider';
import { Link} from 'react-router-dom';

const CommentSection = ({ movieId }) => {
  const [commentsList, setCommentsList] = useState([]);
  const [user, setUser] = useState(null); // State to hold user details
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0.0); // Initialize rating as float
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchComments = async () => {
      try {
        // Fetch comments for the movie
        const response = await databases.listDocuments(
          '6680463f0025c96aaf8e',
          '66b7d7f8001006e6f9a1',
          [Query.equal('movie_id', movieId)]
        );
        setCommentsList(response.documents);
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }
    };

    const fetchUser = async () => {
      try {
        // Get user details if authenticated
        const userResponse = await account.get();
        setUser(userResponse);
      } catch (error) {
        if (error.code !== 401) {
          console.error('Failed to fetch user details:', error);
        }
      }
    };

    fetchComments();
    fetchUser();
  }, [movieId]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to comment.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await databases.createDocument(
        '6680463f0025c96aaf8e', // Database ID
        '66b7d7f8001006e6f9a1', // Collection ID for comments
        'unique()', // Document ID, you can use 'unique()' to auto-generate an ID
        {
          movie_id: movieId,
          user_name: user.name, // Use authenticated user's name
          content: comment,
          rating: rating
        }
      );
      setCommentsList((prevComments) => [...prevComments, response]);
      setComment('');
      setRating(0.0);
    } catch (error) {
      console.error('Failed to submit comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      const filled = rating >= i;
      const half = rating >= i - 0.5 && rating < i;
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={filled ? 'text-yellow-500' : half ? 'text-yellow-300' : 'text-gray-400'}
          onClick={() => setRating(i)}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    return stars;
  };

  return (
    <div className='container mx-auto px-3 py-8'>
      <h3 className='text-xl font-bold text-white mb-4'>Comments</h3>
      {commentsList.length === 0 && (
        <p className='text-white'>No comments yet.</p>
      )}
      {commentsList.length > 0 && (
        <div className='bg-gray-800 p-4 rounded'>
          {commentsList.map((commentItem, index) => (
            <div key={index} className='mb-4'>
              <div className='flex items-center mb-2'>
                <FontAwesomeIcon icon={faUser} className='text-white mr-2' />
                <p className='text-white'><b>{commentItem.user_name}</b></p>
              </div>
              <p className='text-gray-300'>{commentItem.content}</p>
              <div className='flex items-center my-2'>
                {renderStars(commentItem.rating)}<span> ({commentItem.rating})</span>
              </div>
              <p className='text-gray-400 text-sm'>{moment(commentItem.$createdAt).fromNow()}</p>
              <Divider />
            </div>
          ))}
        </div>
      )}

      <h3 className='text-xl font-bold text-white mt-4'>Leave a Comment</h3>
      {error && <p className='text-red-500 mb-4'>{error}</p>}
      {user ? (
        <form onSubmit={handleCommentSubmit} className='bg-gray-800 p-4 rounded mb-8'>
          <div className='mb-4'>
            <label className='block text-white mb-2'>Comment</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className='w-full p-2 rounded text-black'
              rows='4'
              required
            ></textarea>
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2'>Rating</label>
            <div className='flex mb-2'>{renderStars(rating)}</div>
            <input
              type='number'
              step='0.1'
              max='10'
              value={rating}
              onChange={(e) => setRating(parseFloat(e.target.value))}
              className='w-25 p-2 rounded text-black'
              required
            />
          </div>
          <button
            type='submit'
            className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      ) : (
        <p className='text-white'>You must be  <Link to="/login" className='text-blue-500'>logged in</Link>  to leave a comment.</p>
      )}
    </div>
  );
};

export default CommentSection;


