// import { useEffect, useState } from 'react';
// import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
// import logo from '../assets/logo.webp';
// import userIcon from '../assets/user.png';
// import { IoSearchOutline } from "react-icons/io5";
// import { navigation } from '../contants/navigation';
// import { account } from '../appwrite/appwrite'; // Import Appwrite account instance

// const Header = () => {
//     const location = useLocation();
//     const removeSpace = location?.search?.slice(3)?.split("%20")?.join(" ");
//     const [searchInput, setSearchInput] = useState(removeSpace);
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const checkAuthStatus = async () => {
//             try {
//                 const user = await account.get();
//                 setIsLoggedIn(true);
//             } catch (error) {
//                 setIsLoggedIn(false);
//             }
//         };

//         checkAuthStatus();
//     }, []);

//     useEffect(() => {
//         if (searchInput) {
//             navigate(`/search?q=${searchInput}`);
//         }
//     }, [searchInput]);

//     const handleSubmit = (e) => {
//         e.preventDefault();
//     };

//     const handleLogout = async () => {
//         try {
//             await account.deleteSession('current');
//             setIsLoggedIn(false);
//             navigate('/login'); // Redirect to login page after logout
//         } catch (error) {
//             console.error('Failed to logout:', error);
//         }
//     };

//     return (
//         <header className='fixed top-0 w-full h-16 bg-black bg-opacity-50 z-40'>
//             <div className='container mx-auto px-3 flex items-center h-full'>
//                 <Link to={"/"}>
//                     <img
//                         src={logo}
//                         alt='logo'
//                         width={120}
//                     />
//                 </Link>

//                 <nav className='hidden lg:flex items-center gap-1 ml-5'>
//                     {
//                         navigation.map((nav, index) => {
//                             return (
//                                 <div key={nav.label + "header" + index}>
//                                     <NavLink to={nav.href} className={({ isActive }) => `px-2 hover:text-neutral-100 ${isActive && "text-neutral-100"}`}>
//                                         {nav.label}
//                                     </NavLink>
//                                 </div>
//                             );
//                         })
//                     }
//                 </nav>

//                 <div className='ml-auto flex items-center gap-5'>
//                     <form className='flex items-center gap-2' onSubmit={handleSubmit}>
//                         <input
//                             type='text'
//                             placeholder='Search here...'
//                             className='bg-transparent px-4 py-1 outline-none border-none hidden lg:block'
//                             onChange={(e) => setSearchInput(e.target.value)}
//                             value={searchInput}
//                         />
//                     <button className="text-2xl text-white hidden lg:block">
//                     <IoSearchOutline />
//                     </button>
//                     </form>

//                     <div className='w-8 h-8 rounded-full overflow-hidden cursor-pointer active:scale-50 transition-all'>
//                         <img
//                             src={userIcon}
//                             alt="User"
//                             className="w-full h-full"
//                         />
//                     </div>

//                     {isLoggedIn ? (
//                         <button onClick={handleLogout} className='text-white'>
//                             Logout
//                         </button>
//                     ) : (
//                         <Link to="/login" className='text-white'>
//                             Login
//                         </Link>
//                     )}
//                 </div>
//             </div>
//         </header>
//     );
// };

// export default Header;

import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.webp';
import userIcon from '../assets/user.png';
import { IoSearchOutline } from "react-icons/io5";
import { navigation } from '../contants/navigation';
import { account } from '../appwrite/appwrite'; // Import Appwrite account instance

const Header = () => {
    const location = useLocation();
    const removeSpace = location?.search?.slice(3)?.split("%20")?.join(" ");
    const [searchInput, setSearchInput] = useState(removeSpace);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const user = await account.get();
                setIsLoggedIn(true);
                setUserName(user.name);
            } catch (error) {
                setIsLoggedIn(false);
                setUserName('');
            }
        };

        checkAuthStatus();
    }, []);

    useEffect(() => {
        if (searchInput) {
            navigate(`/search?q=${searchInput}`);
        }
    }, [searchInput]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleLogout = async () => {
        try {
            await account.deleteSession('current');
            setIsLoggedIn(false);
            setUserName('');
            navigate('/login'); // Redirect to login page after logout
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <header className='fixed top-0 w-full h-16 bg-black bg-opacity-50 z-40'>
            <div className='container mx-auto px-3 flex items-center h-full'>
                <Link to={"/"}>
                    <img
                        src={logo}
                        alt='logo'
                        width={120}
                    />
                </Link>

                <nav className='hidden lg:flex items-center gap-1 ml-5'>
                    {
                        navigation.map((nav, index) => {
                            return (
                                <div key={nav.label + "header" + index}>
                                    <NavLink to={nav.href} className={({ isActive }) => `px-2 hover:text-neutral-100 ${isActive && "text-neutral-100"}`}>
                                        {nav.label}
                                    </NavLink>
                                </div>
                            );
                        })
                    }
                </nav>

                <div className='ml-auto flex items-center gap-5'>
                    <form className='flex items-center gap-2' onSubmit={handleSubmit}>
                        <input
                            type='text'
                            placeholder='Search here...'
                            className='bg-transparent px-4 py-1 outline-none border-none hidden lg:block'
                            onChange={(e) => setSearchInput(e.target.value)}
                            value={searchInput}
                        />
                    <button className="text-2xl text-white hidden lg:block">
                    <IoSearchOutline />
                    </button>
                    </form>

                    {isLoggedIn ? (
                        <div className="relative">
                            <div className="flex items-center gap-2 cursor-pointer" onClick={toggleDropdown}>
                                <img
                                    src={userIcon}
                                    alt="User"
                                    className="w-8 h-8 rounded-full"
                                />
                                <span className="text-white">{userName}</span>
                            </div>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                                    <button
                                        onClick={handleLogout}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
{/* <div className="flex items-center gap-4 text-sm font-medium">
  <Link to="/register" className="text-white hover:text-gray-200 transition duration-200">
    Create an Account
  </Link>
  <Link to="/login" className="text-white hover:text-gray-200 transition duration-200">
    Login
  </Link>
</div> */},
<div className="flex items-center gap-2 md:gap-4">
  <Link to="/login" className=" hover:bg-gray-900 text-white font-bold text-xs py-0.5 px-1 rounded-sm xs:py-0.5 xs:px-1 md:py-1 md:px-2">
    Login
  </Link>
  <Link to="/register" className="bg-gray-800 hover:bg-gray-900 text-white font-bold text-xs py-0.5 px-1 rounded-sm xs:py-0.5 xs:px-1 md:py-1 md:px-2">
    Create an Account
  </Link>
</div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
