// src/components/RegistrationPage.js
import React, { useState } from 'react';
import { account } from '../appwrite/appwrite'; // Import Appwrite account instance
import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      await account.create('unique()', email, password, name);
      // Redirect or update UI after successful registration
      window.location.href = '/login'; // Redirect to login page or desired route
    } catch (error) {
    //   setError('Registration failed. Please try again.,', error);
      setError(error.message);
    }
  };

  return (
    <div className="container mx-auto px-3 py-16">
      <h2 className="text-2xl font-bold text-center mb-8">Register</h2>
      <form onSubmit={handleRegister} className="max-w-md mx-auto bg-gray-800 p-8 rounded">
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block text-white mb-2">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 rounded text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 rounded text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded text-black"
            required
          />
        </div>
        <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all">
          Register
        </button>
      </form>
      <p className="text-center mt-4">
        Already have an account? <Link to="/login"  className="text-blue-500">Login here</Link>
      </p>
    </div>
  );
};

export default RegistrationPage;
