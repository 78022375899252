import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import ExplorePage from "../pages/ExplorePage";
import DetailsPage from "../pages/DetailsPage";
import SearchPage from "../pages/SearchPage";
import CastDetails from "../pages/CastDetails";
import LoginPage from "../components/LoginPage";
import RegistrationPage from "../components/RegistrationPage";

const router = createBrowserRouter([
    {
        path : "/",
        element : <App/>,
        children : [
            {
                path : "",
                element : <Home/>
            },
            {
                path : ":explore",
                element : <ExplorePage/>
            },
            {
                path : ":explore/:id",
                element : <DetailsPage/>
            },
            {
                path : ":explore/:id/:castId",
                element : <CastDetails/>
            },
            {
                path : "search",
                element : <SearchPage/>
            },
            {
                path : "login",
                element : <LoginPage/>
            },
            {
                path : "register",
                element : <RegistrationPage/>
            }
        ]
    }
])

export default router