import React from 'react'

const Divider = () => {
  return (
    <div className='bg-neutral-700 p-[0.5px] rounded-full my-3'>
      
    </div>
  )
}

export default Divider
