import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Card from '../components/Card'
import GenreFilter from '../components/GenreFilter'

const ExplorePage = () => {
  const params = useParams()
  const [pageNo, setPageNo] = useState(1)
  const [data, setData] = useState([])
  const [totalPageNo, setTotalPageNo] = useState(0)
  const [selectedGenres, setSelectedGenres] = useState([])

  console.log("params", params.explore)
  console.log("data", data)

  const fetchData = async () => {
    try {
      const response = await axios.get(`/discover/${params.explore}`, {
        params: {
          page: pageNo,
          with_genres: selectedGenres.join(',')
        }
      })
      setData((preve) => {
        return [
          ...preve,
          ...response.data.results
        ]
      })
      setTotalPageNo(response.data.total_pages)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      setPageNo(preve => preve + 1)
    }
  }

  const handleGenreChange = (genreId) => {
    setSelectedGenres((prevSelectedGenres) => {
      if (prevSelectedGenres.includes(genreId)) {
        return prevSelectedGenres.filter(id => id !== genreId)
      } else {
        return [...prevSelectedGenres, genreId]
      }
    });
  };

  useEffect(() => {
    fetchData()
  }, [pageNo, selectedGenres])

  useEffect(() => {
    setPageNo(1)
    setData([])
    fetchData()
  }, [params.explore])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    setPageNo(1)
    setData([])
    fetchData()
  }, [selectedGenres])

  return (
    <div className='py-16 flex'>
      <div className='w-1/4'>
        <div className='filter'>
          <GenreFilter selectedGenres={selectedGenres} onGenreChange={handleGenreChange} />
        </div>
      </div>
      <div className='w-3/4'>
        <div className='container mx-auto'>
          <h3 className='capitalize text-lg lg:text-xl font-semibold my-3'>Popular {params.explore} show</h3>

          <div className='grid grid-cols-[repeat(auto-fit,230px)] gap-6 justify-center lg:justify-start'>
            {
              data.map((exploreData, index) => {
                return (
                  <Card data={exploreData} key={`${exploreData.id}-${index}`} media_type={params.explore} />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExplorePage
