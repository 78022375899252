// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import useFetch from '../hooks/useFetch';
// import useFetchDetails from '../hooks/useFetchDetails';
// import { useSelector } from 'react-redux';
// import moment from 'moment';
// import Divider from '../components/Divider';
// import HorizontalScollCard from '../components/HorizontalScollCard';
// import VideoPlay from '../components/VideoPlay';
// import { databases, Query } from '../appwrite/appwrite'; // Import Query from appwrite
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import Comments from '../components/Comments';
// import CommentForm from '../components/CommentForm'; // Import the CommentForm component

// const DetailsPage = () => {
//   const params = useParams();
//   const imageURL = useSelector(state => state.movieoData.imageURL);
//   const { data } = useFetchDetails(`/${params?.explore}/${params?.id}`);
//   const { data: castData } = useFetchDetails(`/${params?.explore}/${params?.id}/credits`);
//   const { data: similarData } = useFetch(`/${params?.explore}/${params?.id}/similar`);
//   const { data: recommendationData } = useFetch(`/${params?.explore}/${params?.id}/recommendations`);
//   const [playVideo, setPlayVideo] = useState(false);
//   const [playVideoId, setPlayVideoId] = useState("");
//   const [appwriteData, setAppwriteData] = useState(null);
//   const [commentsList, setCommentsList] = useState([]);

//   useEffect(() => {
//     const fetchAppwriteData = async () => {
//       try {
//         const response = await databases.listDocuments(
//           '6680463f0025c96aaf8e', 
//           '66804650002239a2bf24',
//           [Query.equal('movie_id', params?.id)]
//         );
//         if (response.documents.length > 0) {
//           setAppwriteData(response.documents[0]);
//         }
//       } catch (error) {
//         console.error('Failed to fetch Appwrite data:', error);
//       }
//     };

//     const fetchComments = async () => {
//       try {
//         const response = await databases.listDocuments(
//           '6680463f0025c96aaf8e',
//           '66b7d7f8001006e6f9a1',
//           [Query.equal('movie_id', params?.id)]
//         );
//         setCommentsList(response.documents);
//       } catch (error) {
//         console.error('Failed to fetch comments:', error);
//       }
//     };

//     fetchAppwriteData();
//     fetchComments();
//   }, [params?.id]);

//   const handlePlayVideo = (data) => {
//     setPlayVideoId(data);
//     setPlayVideo(true);
//   };

//   const handleCommentAdded = (newComment) => {
//     setCommentsList((prevComments) => [...prevComments, newComment]);
//   };

//   const duration = (data?.runtime / 60)?.toFixed(1)?.split(".");
//   const writer = castData?.crew?.filter(el => el?.job === "Writer")?.map(el => el?.name)?.join(", ");


//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 1; i <= 10; i++) {
//       stars.push(
//         <FontAwesomeIcon
//           key={i}
//           icon={faStar}
//           className={i <= rating ? 'text-yellow-500' : 'text-gray-400'}
//         />
//       );
//     }
//     return stars;
//   };

//   return (
//     <div>

// <div className='w-full h-[280px] relative hidden lg:block'>
//         <div className='w-full h-full'>
//           <img
//             src={imageURL + data?.backdrop_path}
//             className='h-full w-full object-cover'
//           />
//         </div>
//         <div className='absolute w-full h-full top-0 bg-gradient-to-t from-neutral-900/90 to-transparent'></div>
//       </div>

//       <div className='container mx-auto px-3 py-16 lg:py-0 flex flex-col lg:flex-row gap-5 lg:gap-10 '>
//         <div className='relative mx-auto lg:-mt-28 lg:mx-0 w-fit min-w-60'>
//           <img
//             src={imageURL + data?.poster_path}
//             className='h-80 w-60 object-cover rounded'
//           />
//           <button onClick={() => handlePlayVideo(data)} className='mt-3 w-full py-2 px-4 text-center bg-white text-black rounded font-bold text-lg hover:bg-gradient-to-l from-red-500 to-orange-500 hover:scale-105 transition-all'>Play Now</button>
//         </div>

//         <div>
//           <h2 className='text-2xl lg:text-4xl font-bold text-white '>{data?.title || data?.name}</h2>
//           <p className='text-neutral-400'>{data?.tagline}</p>

//           <Divider />

//           <div className='flex items-center gap-3'>
//             <p>
//               Rating : {Number(data?.vote_average).toFixed(1)}+
//             </p>
//             <span>|</span>
//             <p>
//               View : {Number(data?.vote_count)}
//             </p>
//             <span>|</span>
//             <p>Duration : {duration[0]}h {duration[1]}m</p>
//           </div>

//           <Divider />

//           {appwriteData && (
//             <div>
//               <h3 className='text-xl font-bold text-white mb-1'>Movique Rating ❤️</h3>
//               <p><span className='text-white'>Rating:</span> {renderStars(appwriteData.star)} ({appwriteData.star}/10)</p>
//               <p><span className='text-white'></span> <b>{appwriteData.title_description}</b></p>
//               <p><span className='text-white'></span> {appwriteData.description}</p>
              
//               <Divider />
//             </div>
//           )}

//           <div>
//             <h3 className='text-xl font-bold text-white mb-1'>Overview</h3>
//             <p>{data?.overview}</p>

//             <Divider />
//             <div className='flex items-center gap-3 my-3 text-center'>
//               <p>
//                 Staus : {data?.status}
//               </p>
//               <span>|</span>
//               <p>
//                 Release Date : {moment(data?.release_date).format("MMMM Do YYYY")}
//               </p>
//               <span>|</span>
//               <p>
//                 Revenue : {Number(data?.revenue)}
//               </p>
//             </div>

//             <Divider />
//           </div>

//           <div>
//             <p><span className='text-white'>Director</span> : {castData?.crew[0]?.name}</p>

//             <Divider />

//             <p>
//               <span className='text-white'>Writer : {writer}</span>
//             </p>
//           </div>

//           <Divider />

//           <h2 className='font-bold text-lg'>Cast :</h2>
//           <div className='grid grid-cols-[repeat(auto-fit,96px)] gap-5 my-4'>
//             {
//               castData?.cast?.filter(el => el?.profile_path).map((starCast, index) => {
//                 return (
//                   <div key={index}>
//                     <div>
//                       <img
//                         src={imageURL + starCast?.profile_path}
//                         className='w-24 h-24 object-cover rounded-full'
//                       />
//                     </div>
//                     <p className='font-bold text-center text-sm text-neutral-400'>{starCast?.name}</p>
//                   </div>
//                 )
//               })
//             }
//           </div>
//         </div>
//       </div>
//       {/* Add CommentForm component */}
//       <div className='container mx-auto px-3 py-8'>
//         <h3 className='text-xl font-bold text-white mb-4'>Leave a Comment</h3>
//         <CommentForm movieId={params?.id} onCommentAdded={handleCommentAdded} />
//       </div>

//       {/* Display Comments */}
//       {commentsList.length > 0 && (
//         <div className='container mx-auto px-3 py-8'>
//           <h3 className='text-xl font-bold text-white mb-4'>Comments</h3>
//           <div className='bg-gray-800 p-4 rounded'>
//             {commentsList.map((commentItem, index) => (
//               <div key={index} className='mb-4'>
//                 <p className='text-white'><b>{commentItem.user_name}</b>:</p>
//                 <p className='text-gray-300'>{commentItem.content}</p>
//                 <Divider />
//               </div>
//             ))}
//           </div>
//         </div>
//       )}

//       {/* Existing content ... */}
//       <div>
//         <HorizontalScollCard data={similarData} heading={"Similar " + params?.explore} media_type={params?.explore} />
//         <HorizontalScollCard data={recommendationData} heading={"Recommendation " + params?.explore} media_type={params?.explore} />
//       </div>

//       {playVideo && (
//         <VideoPlay data={playVideoId} close={() => setPlayVideo(false)} media_type={params?.explore} />
//       )}
//     </div>
//   );
// };

// export default DetailsPage;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import useFetchDetails from '../hooks/useFetchDetails';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Divider from '../components/Divider';
import HorizontalScollCard from '../components/HorizontalScollCard';
import VideoPlay from '../components/VideoPlay';
import { databases, Query } from '../appwrite/appwrite'; // Import Query from appwrite
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import CommentSection from '../components/CommentSection'; // Import the CommentSection component

const DetailsPage = () => {
  const params = useParams();
  const imageURL = useSelector(state => state.movieoData.imageURL);
  const { data } = useFetchDetails(`/${params?.explore}/${params?.id}`);
  const { data: castData } = useFetchDetails(`/${params?.explore}/${params?.id}/credits`);
  const { data: similarData } = useFetch(`/${params?.explore}/${params?.id}/similar`);
  const { data: recommendationData } = useFetch(`/${params?.explore}/${params?.id}/recommendations`);
  const [playVideo, setPlayVideo] = useState(false);
  const [playVideoId, setPlayVideoId] = useState("");
  const [appwriteData, setAppwriteData] = useState(null);

  useEffect(() => {
    const fetchAppwriteData = async () => {
      try {
        const response = await databases.listDocuments(
          '6680463f0025c96aaf8e', 
          '66804650002239a2bf24',
          [Query.equal('movie_id', params?.id)]
        );
        if (response.documents.length > 0) {
          setAppwriteData(response.documents[0]);
        }
      } catch (error) {
        console.error('Failed to fetch Appwrite data:', error);
      }
    };

    fetchAppwriteData();
  }, [params?.id]);

  const handlePlayVideo = (data) => {
    setPlayVideoId(data);
    setPlayVideo(true);
  };

  const duration = (data?.runtime / 60)?.toFixed(1)?.split(".");
  const writer = castData?.crew?.filter(el => el?.job === "Writer")?.map(el => el?.name)?.join(", ");


  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={i <= rating ? 'text-yellow-500' : 'text-gray-400'}
        />
      );
    }
    return stars;
  };

  return (
    <div>

<div className='w-full h-[280px] relative hidden lg:block'>
        <div className='w-full h-full'>
          <img
            src={imageURL + data?.backdrop_path}
            className='h-full w-full object-cover'
          />
        </div>
        <div className='absolute w-full h-full top-0 bg-gradient-to-t from-neutral-900/90 to-transparent'></div>
      </div>

      <div className='container mx-auto px-3 py-16 lg:py-0 flex flex-col lg:flex-row gap-5 lg:gap-10 '>
        <div className='relative mx-auto lg:-mt-28 lg:mx-0 w-fit min-w-60'>
          <img
            src={imageURL + data?.poster_path}
            className='h-80 w-60 object-cover rounded'
          />
          <button onClick={() => handlePlayVideo(data)} className='mt-3 w-full py-2 px-4 text-center bg-white text-black rounded font-bold text-lg hover:bg-gradient-to-l from-red-500 to-orange-500 hover:scale-105 transition-all'>Play Now</button>
        </div>

        <div>
          <h2 className='text-2xl lg:text-4xl font-bold text-white '>{data?.title || data?.name}</h2>
          <p className='text-neutral-400'>{data?.tagline}</p>

          <Divider />

          <div className='flex items-center gap-3'>
            <p>
              Rating : {Number(data?.vote_average).toFixed(1)}+
            </p>
            <span>|</span>
            <p>
              View : {Number(data?.vote_count)}
            </p>
            <span>|</span>
            <p>Duration : {duration[0]}h {duration[1]}m</p>
          </div>

          <Divider />

          {appwriteData && (
            <div>
              <h3 className='text-xl font-bold text-white mb-1'>Movique Rating ❤️</h3>
              <p><span className='text-white'>Rating:</span> {renderStars(appwriteData.star)} ({appwriteData.star}/10)</p>
              <p><span className='text-white'></span> <b>{appwriteData.title_description}</b></p>
              <p><span className='text-white'></span> {appwriteData.description}</p>
              
              <Divider />
            </div>
          )}

          <div>
            <h3 className='text-xl font-bold text-white mb-1'>Overview</h3>
            <p>{data?.overview}</p>

            <Divider />
            <div className='flex items-center gap-3 my-3 text-center'>
              <p>
                Staus : {data?.status}
              </p>
              <span>|</span>
              <p>
                Release Date : {moment(data?.release_date).format("MMMM Do YYYY")}
              </p>
              <span>|</span>
              <p>
                Revenue : {Number(data?.revenue)}
              </p>
            </div>

            <Divider />
          </div>

          <div>
            <p><span className='text-white'>Director</span> : {castData?.crew[0]?.name}</p>

            <Divider />

            <p>
              <span className='text-white'>Writer : {writer}</span>
            </p>
          </div>

          <Divider />

          <h2 className='font-bold text-lg'>Cast :</h2>
          <div className='grid grid-cols-[repeat(auto-fit,96px)] gap-5 my-4'>
            {
              castData?.cast?.filter(el => el?.profile_path).map((starCast, index) => {
                return (
                  <div key={index}>
                    <a href={'/person/know-your-star/'+starCast?.id} target="_blank" rel="noreferrer">
                    <div>
                      <img
                        src={imageURL + starCast?.profile_path}
                        className='w-24 h-24 object-cover rounded-full'
                      />
                    </div>
                    <p className='font-bold text-center text-sm text-neutral-400'>{starCast?.name}</p>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      {/* Integrate CommentSection Component */}
      <CommentSection movieId={params?.id} />

      {/* Existing content ... */}
      <div>
        <HorizontalScollCard data={similarData} heading={"Similar " + params?.explore} media_type={params?.explore} />
        <HorizontalScollCard data={recommendationData} heading={"Recommendation " + params?.explore} media_type={params?.explore} />
      </div>

      {playVideo && (
        <VideoPlay data={playVideoId} close={() => setPlayVideo(false)} media_type={params?.explore} />
      )}
    </div>
  );
};

export default DetailsPage;

